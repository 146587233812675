import { Injectable } from '@angular/core';

import { downgradeInjectable } from '@angular/upgrade/static';

declare var angular: angular.IAngularStatic;

import { StatusConstant } from "../../../constants/status.constant";

@Injectable()
export class StatusService {
    spotStatuses: any;
    deliveriesStatuses: any;
    ordersStatuses: any;

    constructor(private statusConstants: StatusConstant) {
        this.spotStatuses = JSON.parse(JSON.stringify(this.statusConstants.spots));
        this.deliveriesStatuses = JSON.parse(JSON.stringify(this.statusConstants.deliveries));
        this.ordersStatuses = JSON.parse(JSON.stringify(this.statusConstants.orders));
    }

    carbonCopy(statusObj:any):object {
        return {
            color: statusObj.color,
            total: statusObj.total,
            current: statusObj.current,
            status: statusObj.status,
            progress: statusObj.progress,
            label: statusObj.label,
        };
    }

    readSpotStatus(spot:any):any {
        let self = this;

        // Prevent infinite digest cycle, by returning something that can be equated to itself in watches
        if (spot === null) {
            return '';
        }

        let status:any = {};

        try {
            if (spot.status === 'UNSENT' && spot.reslateFlag) {
                status = self.carbonCopy(self.spotStatuses[spot.status]);
                status.status = 'Reslate Needed';
                status.label = 'The spot metadata has changed and needs a re-slate.';
            } else {
                status = spot.ParentOrderItem
                    ? self.carbonCopy(self.deliveriesStatuses[spot.ParentOrderItem.deliveryStatus])
                    : self.carbonCopy(self.spotStatuses[spot.status]);
            }

            status.progress = spot.progress;
        } catch (e) {
            //spot not loaded
        }

        return status;
    }

    readOrderStatus(order:any):any {
        let self = this;

        let status = {};

        try {
            status = self.carbonCopy(self.ordersStatuses[order.deliveryStatus]);
        } catch (e) {
            //order not loaded
        }

        return status;
    }

    readOrderItemStatus(orderItem:any):any {
        let self = this;
        let status:any = {};

        try {
            if (
                orderItem.Spot &&
                (orderItem.Spot.status === 'PENDING_QC' ||
                    orderItem.Spot.status === 'PENDING_PROD_SVCS' ||
                    orderItem.Spot.status === 'REJECTED') &&
                (orderItem.deliveryStatus !== 'COMPLETED' ||
                    orderItem.deliveryStatus !== 'CANCELLED')
            ) {
                status = self.carbonCopy(self.spotStatuses[orderItem.Spot.status]);
            } else {
                status = self.carbonCopy(self.deliveriesStatuses[orderItem['deliveryStatus']]);
            }
        } catch (e) {
            //orderItem not loaded
        }

        if(orderItem.deliveryStatus === 'REMOVED_FROM_ORDER' && orderItem.previouslyDeliveredOrderIdAndGroupName) {
            status.label += ' See Order #' + orderItem.previouslyDeliveredOrderIdAndGroupName;
        }

        return status;
    }

    readViewStatus=(spot:any):any => {
        // Prevent infinite digest cycle, by returning something that can be equated to itself in watches
        if (spot === null) {
            return '';
        }

        let self = this;
        let status:any = {};

        try {
            if (spot.ParentOrderItem) {
                status = self.carbonCopy(self.deliveriesStatuses[spot.ParentOrderItem.deliveryStatus]);

                if (
                    spot.duration > spot.expectedDuration &&
                    spot.ParentOrderItem.deliveryStatus === 'COMPLETED'
                ) {
                    status.status = 'Long Duration Delivered';
                } else if (spot.ParentOrderItem.deliveryStatus === 'COMPLETED') {
                    status.status = 'Delivered';
                }
            } else {
                status = self.carbonCopy(self.spotStatuses[spot.status]);
            }

            if (spot.status === 'UNSENT' && spot.reslateFlag) {
                status.status = 'Reslate Needed';
                status.label = 'The spot metadata has changed and needs a re-slate.';
            }

            status.progress = spot.progress;
        } catch (e) {
            //spot not loaded
        }

        return status;
    };

    readLabel(item:any):any {
        let self = this;

        try {
            if (item.orderType) {
                return self.ordersStatuses[item.deliveryStatus].label;
            }
            return item.OrderItem
                ? self.deliveriesStatuses[item.OrderItem.deliveryStatus].label
                : self.spotStatuses[item.status].label;
        } catch (e) {
            // not loaded
        }
    }
}

angular.module('comcast.common.communication').factory('StatusService', downgradeInjectable(StatusService));
